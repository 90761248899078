import { ChangeEvent, FC, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { Box, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { Pagination } from '@mui/material';
import { pagination } from 'theme/custom/pagination';
import { useTranslation } from 'react-i18next';
import { saveDefaultPerPage } from 'utils/paginate';

const PREFIX = 'PaginationControls';

const classes = {
  pagination: `${PREFIX}Pagination`,
};

const StyledBox = styled(Box)({
  [`&.${classes.pagination}`]: pagination,
});

interface PaginationControlProps {
  readonly page?: number;
  readonly perPageOptions?: readonly number[];
  readonly perPage: number;
  readonly disabled?: boolean;
  readonly totalRowCount: number;
  readonly onPerPageChange: (size: number) => void;
  readonly onPageChange: (page: number) => void;
}

const defaultPerPageOptions = [10, 50, 100];

const PaginationControls: FC<PaginationControlProps> = ({
  page,
  perPageOptions = defaultPerPageOptions,
  perPage,
  totalRowCount,
  onPerPageChange,
  onPageChange,
  disabled = false,
}) => {
  const { t } = useTranslation();

  const handlePerPageSelectChange = useCallback(
    (event: SelectChangeEvent) => {
      const value = Number(event.target.value);
      saveDefaultPerPage([], value);
      onPerPageChange(value);
    },
    [onPerPageChange, perPageOptions]
  );

  const handlePaginationChange = useCallback(
    (_event: ChangeEvent<any>, value: number) => {
      onPageChange(value);
    },
    [onPageChange]
  );

  const pageCount = Math.ceil(totalRowCount / perPage);

  return (
    <StyledBox className={classes.pagination}>
      <Select
        disabled={disabled}
        name="perPage"
        value={String(perPage)}
        onChange={handlePerPageSelectChange}
        variant="outlined"
      >
        {perPageOptions.map((size, i) => (
          <MenuItem key={`${size}-${i}`} value={size}>
            {t('per_page', { count: size })}
          </MenuItem>
        ))}
      </Select>
      <Pagination onChange={handlePaginationChange} page={page} count={pageCount} />
    </StyledBox>
  );
};

export default PaginationControls;
